@font-face {
  font-family: Montserrat-regular;
  src: local('Jakarta-regular'), url(./fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: Montserrat-semi;
  src: local('Jakarta-regular'), url(./fonts/Montserrat/Montserrat-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: Montserrat-bold;
  src: local('Jakarta-bold'), url(./fonts/Montserrat/Montserrat-Bold.ttf) format('truetype');
}
body{
  margin: 0;
  overflow-x: hidden;
}
.nav-container{
  position: fixed;
  height: 12vh;
  width: 100%;
  cursor: default;
  padding-left: 5%;
  padding-right: 5%;
  top: 0;
  z-index: 2;
}
.nav-wrap{
  height: inherit;
  width: 90%;
  display: flex;
  flex: 6;
}
#nav-bg{
  background-color: #FFFFFF;
  color: black;
}

#remove-bg{
  background-color: transparent;
  color: #FFFFFF;
}
.nav-logo{
  display: flex;
  flex: 1;
  align-items: center;
}
.nav-logo img{
  width: 120px;
  height: 60px;
}
.nav-list{
  display: flex;
  flex: 4;
  justify-content: end;
  position: relative;
}
.list-item{
  display: flex;
  align-items: center;
  width: fit-content;
  margin-left: 80px;
  font-size: 18px;
  font-family: Montserrat-semi;
}
.dropdown{
  position: absolute;
  top: 12vh;
  width: 250px;
  height: auto;
  right: 35%;
  z-index: 1;
  padding-bottom: 20px;
  background-color: #FFFFFF;
  box-shadow: 0px 20px 60px 0px #0000000D;
}
.dropdown-item{
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-size: 17px;
  font-family: Montserrat-regular;
  color: grey;
}
.dropdown-item:hover{
  color: black;
}
.btn-icon{
  margin-left: 10px;
  font-size: 14px;
}
.nav-btn{
  display: flex;
  flex: 1;
  justify-content: end;
}
.btn-1{
  height: inherit;
  width: 50px;
  height: inherit;
  border-radius: 8px;
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 28px;
}
.side-container{
  height: auto;
  position: fixed;
  bottom: 0;
  left: -350px;
  height: 100%;
  width: 300px;
  display: none;
  background-color: #FFFFFF;
  transform: left 1s;
  z-index: 13;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0px 20px 60px 0px #0000000D;
}
.close-icon{
  padding-right: 20px;
  width: fit-content;
  height: fit-content;
  position: absolute;
  right: 0;
  top: 20px;
  font-size: 18px;
}
.side-item-wrap{
  margin-top: 50px;
}
.side-item{
  font-size: 17px;
  font-family: Montserrat-regular;
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;
}
.angle-right{
  position: absolute;
  right: 0;
}
.angle-down{
  position: absolute;
  right: 0;
}
.side-list{
}
.side-1{
  height: 35px;
  font-size: 17px;
  font-family: Montserrat-regular;
}
#slide-in{
  left: 0;
}
.side-header{
  font-family: Montserrat-semi;
}
.home-container{
  height: 550px;
  position: relative;
}
.home-wrap{
  display: flex;
  flex: 6;
  height: inherit;
}
.home{
  flex: 1;
}
.home-intro-wrap{
  position: absolute;
  bottom: 100px;
  width: 800px;
  left: 150px;
}

.mobile-intro-wrap{
  position: absolute;
  bottom: 100px;
  width: 800px;
  left: 150px;
  display: none;
}
.home-intro{
  height: fit;
  font-size: 50px;
  font-family: Montserrat-semi;
  color: #FFFFFF;
  z-index: 1;
}
.sub-intro{
  height: fit;
  font-size: 50px;
  font-family: Montserrat-semi;
  color: #FFFFFF;
  z-index: 1;
}
.home img{
  width: 100%;
  height: 100%;
}
.about-container{
  height: 700px;
  padding-right: 5%;
  border: 2px solid #FFFFFF;
}
.about-wrap{
  display: flex;
  width: 100%;
  flex: 2;
  margin-top: 150px;
}

.about-1{
  display: flex;
  flex: 1;
  padding-left: 30px;
}
.about-image{
  height: 500px;
  width: 500px;
  border-radius: 100%;
  background-color: #2596be;
}
.about-image img{
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.about-2{
  flex: 1;
}
.about-header{
  font-size: 38px;
  font-family: Montserrat-semi;
}
.about-desc{
  font-size: 22px;
  font-family: Montserrat-rgular;
  line-height: 35px;
  margin-top: 35px;
}
.com-container{
  display: flex;
  justify-content: center;
  background-color: azure;
  padding-top: 50px;
  padding-bottom: 100px;
}
.com-wrap{
  width: 90%;
}
.g-header{
  width: 100%;
  font-size: 48px;
  font-family: Montserrat-semi;
  text-align: center;
}
.row-1{
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.row-2{
  display: flex;
  margin-top: 50px;
  justify-content: center;
}
.jema{
  background-color: transparent;
  width: 400px;
  height: 500px;
  perspective: 1000px;
  border-radius: 8px;
  margin: 10px;
}
.jema-inner{
  position: relative;
  width: inherit;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}
.jema-front{
  width: 100%;
  height: 100%;
}
.jema-front img{
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.jema-front, .jema-back, .front-icon{
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.jema-back{
  width: 90%;
  height: 100%;
  padding-left: 5%;
  padding-right: 5%;
  border-radius: 8px;
  border: 2px solid grey;
  position: relative; 
  transform: rotateY(180deg);
}
.j-front{
  width: 100%;
  height: 100%;
  opacity: 0.7;
}
.front-icon{
  position: absolute;
  height: fit-content;
  width: 100%;
  top: 200px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-family: Montserrat-bold;
  text-align: center;
  color: #FFFFFF;
}
.front-icon img{
  width: 80px;
  height: 80px;
}

.icon-wrap{
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.jema-icon{
  display: flex;
  justify-content: center;
  height: fit-content;
}
.jema-icon img{
  width: 100%;
  height: 100%;
}
.j-header{
  font-size: 22px;
  font-family: Montserrat-semi;
  text-align: center;
  margin-top: 20px;
}
.j-desc{
  text-align: center;
  font-family: Montserrat-regular;
  margin-top: 20px;
  font-size: 18px;
}
.j-btn{
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  cursor: default;
}
.btn-5{
  width: 100px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: #FFFFFF;
  background-color: #041470;
  font-family: Montserrat-regular;
  font-size: 18px;
}
.jema:hover .jema-inner {
  transform: rotateY(180deg);
}
.contact-container{
  height: auto;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #FFFFFF;
  padding-top: 100px;
  padding-bottom: 100px;
  overflow-x: hidden;
}
.contact-wrap{
  width: 90%;
  display: flex;
  flex: 2;
}
.contact-1{
  flex: 1;
}
.header-1{
  font-size: 48px;
  font-family: Montserrat-semi;
  margin-bottom: 30px;
}
.c-time{
  height: fit-content;
  width: fit-content;
  font-size: 18px;
  font-family: Montserrat-regular;
}
.social-wrap{
  margin-top: 50px;
}

.social-wrap-2{
  display: flex;
  margin-top: 50px;
  width: 90%;
}
.c-visit{
  font-size: 28px;
  font-family: Montserrat-semi;
  height: fit-content;
  width: fit-content;
}
.social-1{
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: grey
}

.social-2{
  border: 2px solid black;
  color: grey;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-right: 30px;
}
.social-1:hover{
  color: #000000;
}


.social-2:hover{
  color: #000000;
}

.social-text{
  display: flex;
  height: fit-content;
  width: fit-content;
  font-size: 18px;
  font-family: Montserrat-regular;
  margin-top: 20px;
}
.location-wrap{
  margin-top: 20px;
  display: flex;
  height: fit-content;
  align-items: center;
}
.loc-text{
  height: fit-content;
  width: fit-content;
  font-size: 18px;
  font-family: Montserrat-regular;
  margin-left: 10px;
}
.contact-2{
  flex: 1;
  display: flex;
  justify-content: end;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  
}
.form-input-wrap{
  border: 2px solid black;
  height: 80px;
  width: 80%;
  position: relative;
  padding-left: 15px;
  border-radius: 8px;
}

.form-input-wrap-1{
  border: 2px solid black;
  height: 80px;
  width: 80%;
  position: relative;
  padding-left: 15px;
  border-radius: 8px;
  margin-top: 30px;
}

.form-input-wrap-2{
  border: 2px solid black;
  height: 150px;
  width: 80%;
  position: relative;
  padding-left: 15px;
  border-radius: 8px;
  margin-top: 30px;
}
.form-input{
  width: 95%;
  font-size: 18px;
  position: absolute;
  bottom: 10px;
  font-family: Montserrat-semi;
  border: none;
  outline: none;
  color: black;
}
.form-label{
  position: absolute;
  top: 10px;
  font-size: 18px;
  font-family: Montserrat-regular;
  color: grey;
}
textarea{
  width: 95%;
  font-size: 18px;
  position: absolute;
  bottom: 10px;
  height: 100px;
  font-family: Montserrat-semi;
  outline: none;
  border: none;
  color: black;
  resize: none;
}
.contact-btn-wrap{
  width: 80%;
  margin-top: 20px;
  display: flex;
  justify-content: end;
}

.contact-btn{
  width: fit-content;
  font-family: Montserrat-semi;
  font-size: 18px;
  padding: 15px;
  border-radius: 8px;
  color: #FFFFFF;
  cursor: pointer;
  background-color: #041470;
}
.footer-container{
  background-color: #041470;
  height: 20vh;
  display: flex;
  align-items: end;
  justify-content: center;
  font-size: 18px;
  font-family: Montserrat-regular;
  color: #FFFFFF;
}

@media screen and (max-width: 1020px) and (min-width:50px){
  body{
    width: 100vw;
    overflow-x: hidden;
    margin-right: 0px;
  }
  *{
    margin: 0;
    padding: 0;
  }
  .nav-container{
    padding-left: 0%;
    padding-right: 0%;
  }
  .nav-wrap{
    width: 100%;
  }
  .btn-1{
    display: flex;
    margin-right: 20%;
  }

  .nav-list{
    display: none;
  }

  .group-list{
    flex-direction: column;
  }
  
  .group-list-1{
    flex-direction: column;
    margin-top: 10px;
  }

  .contact-container{
    padding-left: 0%;
    padding-right: 0%;
  }
  .contact-wrap{
    flex-direction: column;
    width: 90%;
  }
  .contact-2{
    padding-left: 5%;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    width: 100%;
  }

  .contact-1{
    padding-left: 5%;
  }

  .social-wrap-2{
    flex-direction: column;
    margin-top: 15px;
  }
  .social-2{
    margin-bottom: 15px;
  }

  .form-input-wrap{
    width: 100%;
  }
  
  .form-input-wrap-1{
    width: 100%;
  }

  .form-input-wrap-2{
    width: 100%;
  }

  .about-container{
    padding-right: 0%;
  }
  .about-wrap{
    flex-direction: column-reverse;
    width: 100%;
  }

  .home-intro{
    bottom: 50px;
    left: 20px;
    font-size: 40px;
  }

  .home-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .side-container{
    display: block;
  }

  .about-1{
    padding-left: 0px;
    justify-content: center;
    margin-top: 50px;
    
  }
  .about-image{
    display: none;
  }
  
  .about-2{
    text-align: center;
  }
  .footer-container{
    width: 100%;
  }
  .home-intro-wrap{
    left: 20px;
    width: fit-content;
    font-size: 40px;
    display: none;
  }
  .mobile-intro-wrap{
    left: 15px;
    width: fit-content;
    font-size: 40px;
    display: block;
    padding-right: 5px;
  }
  .row-1{
    flex-direction: column;
  }

  .row-2{
    flex-direction: column;
  }

  .jema{
    margin-bottom: 50px;
    width: 380px;
  }

}